import styled from "styled-components";
import { BsFilterLeft } from "react-icons/bs"
import cssMixin from "@src/styles";
import { P } from "@src/components/atoms";
import SearchBox from "./search/SearchBox";
import { ThemeType } from "@src/types/theme";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";
import { useTranslation } from "next-i18next";

export type TopBarProps = {
    fliterNum: number;
    filterOpen: boolean;
	setFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function TopBar(props: TopBarProps)
{
    const { t } = useTranslation("common");
    const media = useSelector((state: RootState) => state.media);
    

    function handleFilterTogle()
    {
        if (media.deviceLevel !== "labtopL")
		    props.setFilterOpen(!props.filterOpen);
	};

    

    return (
        <Wrapper className="notranslate">
            <Container>
                <FilterWrap onClick={ handleFilterTogle }>
                    <BsFilterLeft size={ 25 } color={ "#f3b25a" } />
                    <P style={{ margin: "2px 0 0 5px" }} pxSize={ 16 } color="#F3B25A" fontFamily="Pretendard" fontWeight="bold">{ "필터" }</P>
                    <P style={{ margin: "2px 0 0 5px" }} pxSize={ 16 } color="#999" fontFamily="Pretendard" fontWeight="bold">{ props.fliterNum }</P>
                </FilterWrap>
                <SearchWrap>
                    <SearchBox />
                </SearchWrap>
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 75px;
    background-color: #251364;
    padding: 0 32px;
    position: sticky;
    top: 0;
    z-index: 1;

    ${({ theme }: { theme: ThemeType }) => theme.tablet!`
        height: fit-content;
        padding: 20px 32px 10px;
	`}
`;

const Container = styled.div`
    ${ cssMixin.container };
    height: 100%;
    display: flex;
    padding: 0 30px;
    align-items: center;

    ${({ theme }: { theme: ThemeType }) => theme.tablet!`
        width: 100%;
        padding: 0;
        flex-direction: column-reverse;
        align-items: start;
	`}
`;

const FilterWrap = styled.div`
    width: fit-content;
    display: flex;
    position: relative;
    cursor: pointer;

    ${({ theme }: { theme: ThemeType }) => theme.tablet!`
        position: inherit;
        margin-top: 12px;
	`}
`;

const SearchWrap = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    ${({ theme }: { theme: ThemeType }) => theme.tablet!`
        width: 100%;
        max-width: none;
        position: inherit;
        transform: none;
	`}
`;