import { P } from "@src/components/atoms";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { ThemeType } from "@src/types/theme";
import FilterSubCaterogy, { SubCategoryProps } from "./FilterSubCaterogy";
import LibraryService from "@src/services/library/LibraryService";
import { useRouter } from "next/router";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

export type CategoryProps = {
    id: string;
    name: string;
    num: number;
    tags: string;
    setCtgName?: any;
    setSubName?: any;
    filterList: string[];
    setFilterList: any;
    filterQuery: string;
    setFilterQuery: any;
};

export default function FilterCaterogy(props: CategoryProps)
{
    const [ open, setOpen ] = useState(false);
    const [ subList, setSubList ] = useState<SubCategoryProps[]>([]);
    const [ number, setNumber ] = useState(props.num);
    const [ subTagMap, setSubTagMap ] = useState(new Map());

    const router = useRouter();

    function handleMenuOpen()
    {
        setOpen(!open);
    }

    useEffect(() => {
        LibraryService.subCategoryList({ target: ["*"], category: props.id, subCd: "" })
        .then(res => {
            let tempArray: SubCategoryProps[] = [];
            
            if (res)
            {
                res.forEach((data: any) => {
                    let newCategory: SubCategoryProps = {
                        id: data.MST_CD,
                        subCd: data.SUB_CD,
                        parentName: "",
                        name: data.SUB_CD_NM,
                        number: 0,
                        filterList: props.filterList,
                        setFilterList: props.setFilterList,
                        subIdx: 0,
                        localeName: router.locale === "ko" ? data.SUB_CD_NM : data.SUB_CD_NM_EN,
                    }

                    tempArray.push(newCategory);
                });
            }

			setSubList(tempArray);
        });
    }, []);

    useEffect(() => {
        if (props.tags)
        {
            let tagArray = props.tags.split(", ");
            let tagMap: Map<string, number> = new Map();
            
            tagArray.forEach(tag => {
                if (!tag)
                    return;

                let currnetNum: number = tagMap.get(tag) ? tagMap.get(tag)! : 0;
                tagMap.set(tag, currnetNum + 1);
            });

            setNumber(tagArray.length - 1);
            setSubTagMap(tagMap);
        }
    }, [props.tags]);

    useEffect(() => {
        if (router.query.id === props.id)
        {
            setOpen(true);
            props.setCtgName && props.setCtgName(props.name);
        }

        if (!router.query.cd)
            props.setSubName && props.setSubName("");
    }, [router.query])

    if (number === 0)
    {
        return (
            <></>
        )
    }
    else
    {
        return (
            <Wrapper>
                <div style={{ display: "flex", cursor: "pointer" }} onClick={ handleMenuOpen }>
                    <MenuBox>
                        <MiddleText bold={ router.query.id === props.id }>{ props.name }</MiddleText>
                    </MenuBox>
                    <OnOffIcon>
                        { open ? <AiOutlineMinus size={ 25 } /> : <AiOutlinePlus size={ 25 } /> }
                    </OnOffIcon>
                </div>
                <MenuCollpase open={ open }>
                    <Collapse isOpened={ open }>
                        { subList && subList.map((sub, idx) =>
                            <FilterSubCaterogy
                                id={ props.id }
                                subCd={ sub.subCd }
                                parentName={ props.name }
                                name={ sub.name }
                                number={ subTagMap.get(sub.name) ? subTagMap.get(sub.name) : 0 }
                                filterList={ props.filterList }
                                setFilterList={ props.setFilterList }
                                subIdx={ idx }
                                filterQuery={ props.filterQuery }
                                setFilterQuery={ props.setFilterQuery }
                                localeName={ sub.localeName }
                                key={ "sub" + idx }
                            />)
                        }
                    </Collapse>
                </MenuCollpase>
            </Wrapper>
        );
                }
}

const Wrapper = styled.div<{ indent?: number }>`
    width: 100%;
    padding: 15px 0;
    border-bottom: solid 1px #373737;
`;

const MenuCollpase = styled.div<{ open: boolean }>`
    width: 100%;
    display: flex;
    padding-top: ${ props => props.open ? "0.12rem" : 0 }
`;

const MenuBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const OnOffIcon = styled.div`
    display: flex;
    align-items: center;
`;

const NormalText = styled(P)`
    font-size: 0.20rem;
    font-weight: 300;
    text-align: left;
    line-height: 0.5rem;
    color: ${({ theme }: { theme: ThemeType }) => theme.text};
`;

const BoldText = styled(NormalText)`
    font-weight: bold;
    line-height: 0.75rem;
`;

const MiddleText = styled(NormalText)<{ bold: boolean }>`
    color: #FFF;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
`;

const SmallText = styled(NormalText)`
    font-size: 0.18rem;
    color: gray;
`;