import { IncomingMessage } from 'http';
const qs = require('qs');

import { baseConfig } from '../api';

export const dataNumConfig = (data: { tags?: any, search?: string }) => 
	baseConfig().post('/api/library/getDataNum', data);

export const mainConfig = (data: { limit: number, page: number, filter?: any, locale: string }) => 
	baseConfig().post('/api/library/getMainLibraries', qs.stringify(data, { arrayFormat: 'repeat' }));

export const listConfig = (data: { target: string[], libraryId?: string[], cond?: string[], limit: number, page: number }) => 
	baseConfig().post('/api/library/getLibraries', qs.stringify(data, { arrayFormat: 'repeat' }));

export const listSubjectConfig = (data: { target: string[], ctg?: string, subCd?: string, limit: number, page: number }) => 
	baseConfig().post('/api/library/getLibrariesBySubject', qs.stringify(data, { arrayFormat: 'repeat' }));
	
export const listConditionConfig = (data: { target: string[], cond: string, limit: number, page: number }) => 
	baseConfig().post('/api/library/getLibrariesByCondition', qs.stringify(data, { arrayFormat: 'repeat' }));

export const categoryConfig = (data: { target?: string[], categoryId?: string[], locale?: string }) => 
	baseConfig().post('/api/library/getCategories', qs.stringify(data, { arrayFormat: 'repeat' }));

export const subCategoryConfig = (data: { target: string[], category: string, subCd: string }) => 
	baseConfig().post('/api/library/getSubCategories', qs.stringify(data, { arrayFormat: 'repeat' }));

export const searchListConfig = (data: { keywords: string, limit: number, page: number }) => 
	baseConfig().post('/api/library/getSearchList', qs.stringify(data, { arrayFormat: 'repeat' }));