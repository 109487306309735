import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaSearch } from "react-icons/fa";
import { ThemeType } from '@src/types/theme';
import { useRouter } from 'next/router';
import { darkTheme } from '@src/theme/default';
import { useTranslation } from 'next-i18next';

export type SearchBoxProps = {
    style?: React.CSSProperties;
};

export default function SearchBox(props: SearchBoxProps)
{
    const { t } = useTranslation("common");
    const router = useRouter();

    const [ search, setSearch ] = useState("");
    const [ isChange, setIsChange ] = useState(false);
    const [ stringColor, setStringColor ] = useState("#808080");

    function handleChange(e: React.ChangeEvent<HTMLInputElement>)
    {
        setSearch(e.target.value);
        setStringColor("#000000");
    }

    function onClick()
    {
        // if (!isChange)
        //     setSearch("");
    }

    function doSearch()
    {
        router.push(`/page/1?search=${search.replace(" ", "")}`);
    }

    useEffect(() => {
        function downEnter(e: KeyboardEvent)
        {
            if (e.key === "Enter" && document.activeElement!.getAttribute("type") === "search")
                doSearch();
        }

        document.addEventListener('keydown', downEnter);

        return () => {
            document.removeEventListener('keydown', downEnter);
        };
    }, [search]);

    // useEffect(() => {
    //     if (router.query.tags)
    //         setSearch("아티스트 밴드 트랙 검색");
    // }, [router.query.tags]);
    
    return (
        <Wrapper style={ props.style }>
            <Search type="search" value={ search } placeholder={ "아티스트 밴드 트랙 검색" } onChange={ handleChange } onClick={ onClick } color={ darkTheme.textHighlight } />
            <SearchButton onClick={ doSearch }>{ "검색" }</SearchButton>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    max-width: 500px;
    height: 40px;
	margin: 0 0.25rem;
	display: flex;
    border: none;
    flex-grow: 1;
    flex: 2;

    ${({ theme }: { theme: ThemeType }) => theme.tablet!`
        width: 100%;
        max-width: none;
        margin: 0;
	`}
`;

const Search = styled.input`
	width: 418px;
    /* min-width: 80%; */
    height: 40px;
	padding: 0 0.25rem 0 0.13rem;
	display: flex;
    border: none;
    border-radius: 9px;
    background-color: white;
    color: #271565;
    font-family: Pretendard; 
    font-size: 14px;
    font-weight: 500;

    ${({ theme }: { theme: ThemeType }) => theme.tablet!`
        width: 100%;
	`}
`;

const SearchButton = styled.div`
    width: 80px;
    height: 40px; 
    margin-left: 12px;
    border-radius: 8px;
    background: #F3B25A; 
    color: #271565;
    text-align: center;
    font-family: Pretendard; 
    font-size: 16px;
    font-weight: 700;
    line-height: 40px; 
    cursor: pointer;
`;