import styled from "styled-components";
import React, { useContext, useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import P from "@src/components/atoms/p";
import { ThemeType } from "@src/types/theme";
import FilterCaterogy, { CategoryProps } from "./category/FilterCaterogy";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";
import X from "@src/components/icons/X";
import { Button } from "@src/components/atoms";
import { PageProps } from "@pages/page/[page]";
import { PagePropsContext } from "@src/context/Pages";
import { useTranslation } from "next-i18next";

export type FilterMenuProps = {
    close?: () => void;
    setCtgName?: any;
    setSubName?: any;
    filterList: string[];
    setFilterList: any;
};

export default function FilterMenu(props: FilterMenuProps)
{
    const { t } = useTranslation("page");
    const media = useSelector((state: RootState) => state.media);
    const router = useRouter();
    const pageProps: PageProps = useContext(PagePropsContext);

    const [ categoryList, setCategoryList ] = useState<CategoryProps[]>([]);
    const [ filterArray, setFilterArray ] = useState<string[]>([]);
    const [ filterQuery, setFilterQuery ] = useState("");

    function handleApplyClick()
    {
        // labtopL이 아닐때만 작동
        if (media.deviceLevel === "labtopL")
            return;

        router.push({ pathname: "/page/[page]", query: { page: router.query.page, tags: filterQuery }});

        if (props.close)
            props.close();
    }

    function handleIntiClick()
    {
        setFilterQuery("");
        router.push({ pathname: "/page/[page]", query: { page: router.query.page }});
    }

    useEffect(() => {
        setCategoryList([ ...pageProps.categoryData ]);

        if (pageProps.tagData)
        {
            let tag: Map<string, string> = new Map(Object.entries(JSON.parse(pageProps.tagData)));
            setFilterArray([ "", tag.get("LIBRARY_GENRE_CD")!, tag.get("MOOD_CD")!, tag.get("THEME_CD")!, tag.get("SCENE_CD")!, tag.get("SOUND_EFFECT_CD")!, tag.get("BGM_CD")!, tag.get("LIBRARY_INST_CD")!, tag.get("NATION")! ]);
        }
    }, [router.locale]);

	return (
        <>
            <Wrapper>
                { media.deviceLevel !== "labtopL" &&
                    <Head>
                        <div onClick={ handleIntiClick }>
                            <P pxSize={ 18 } color="white" fontFamily="Pretendard">{ "초기화" }</P>
                        </div>
                        <div>
                            <P pxSize={ 18 } color="#F3B25A" fontFamily="Pretendard">{ "필터" }</P>
                        </div>
                        <div onClick={ props.close }>
                            <X fill="white" />
                        </div>
                    </Head>
                }
                <MenuCollpase>
                    { categoryList.map((ctg, idx) =>
                        <FilterCaterogy
                            { ...ctg }
                            tags={ filterArray[idx] }
                            key={ "first" + idx }
                            setCtgName={ props.setCtgName }
                            setSubName={ props.setSubName }
                            filterList={ props.filterList }
                            setFilterList={ props.setFilterList }
                            filterQuery={ filterQuery }
                            setFilterQuery={ setFilterQuery }
                        />
                    )}
                </MenuCollpase>
            </Wrapper>
            { media.deviceLevel !== "labtopL" &&
                <BtnWrap>
                    <ApplyBtn onClick={ handleApplyClick }>적용하기</ApplyBtn>
                </BtnWrap>
            }
        </>
	);
}

const Wrapper = styled.div`
    min-width: 300px;
    max-width: 300px;
    padding: 0 30px;
    background-color: #1B0E47;

    ${({ theme }: { theme: ThemeType }) => theme.labtop!`
        position: relative;
	`};

    ${({ theme }: { theme: ThemeType }) => theme.labtop!`
        height: fit-content;
	`};
`;

const Head = styled.div`
    width: 300px;
    height: 70px;
    display: flex;
    position: sticky;
    top: 0;
    justify-content: space-between;
    align-items: center;
    margin: 0 -30px;
    padding: 0 30px;
    background-color: #251364;

    div {
        flex: 1;
        display: flex;

        &:first-child {
            justify-content: start;
            cursor: pointer;
        };

        &:nth-child(2) {
            justify-content: center;
            cursor: default;
        };

        &:last-child {
            justify-content: end;
            cursor: pointer;
        };
    }
`;

const MenuCollpase = styled.div`
    width: 100%;
    height: fit-content;
    min-height: 100%;
`;

const NormalText = styled(P)`
    color: ${({ theme }: { theme: ThemeType }) => theme.text};
    font-size: 0.20rem;
    font-weight: 300;
    text-align: left;
    line-height: 0.5rem;
`;

const BtnWrap = styled.div`
    padding: 20px;
    background-color: #1B0E47;

    ${({ theme }: { theme: ThemeType }) => theme.labtop!`
        position: sticky;
        bottom: 0;
	`};
`;

const ApplyBtn = styled(Button)`
    width: 100%;
    height: 48px; 
    border-radius: 8px;
    background: #F3B25A; 
    color: #271565;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 5px 5px 5px;
`;