import { CheckBox, P } from "@src/components/atoms";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { ThemeType } from "@src/types/theme";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "@src/store";

export type SubCategoryProps = {
    id?: string;
    subCd: string;
    parentName: string;
    name: string;
    number: number;
    filterList: string[];
    setFilterList: any;
    subIdx: number;
    filterQuery?: string;
    setFilterQuery?: any;
    localeName: string;
};

export default function FilterSubCaterogy(props: SubCategoryProps)
{
    const router = useRouter();
    const media = useSelector((state: RootState) => state.media);

    const [ check, setCheck ] = useState(false);

    const handleButtonClick = () => {
        // query에 전달
        let queryTags: any = router.query.tags ? 
                                JSON.parse(router.query.tags as string)
                                :
                                (media.deviceLevel === "labtopL" || !props.filterQuery ?
                                    new Object()
                                    :
                                    JSON.parse(props.filterQuery!)
                                );

        if (queryTags[`${props.parentName}`])
        {
            if (queryTags[`${props.parentName}`].includes(`${props.name}-${props.localeName}`))
                queryTags[`${props.parentName}`] = queryTags[`${props.parentName}`].replace(`${`${props.name}-${props.localeName}`}`, " ").replace("+ ", "").replace(" +", "").replace(" ", "");
            else
                queryTags[`${props.parentName}`] = queryTags[`${props.parentName}`] + "+" + `${props.name}-${props.localeName}`;

            if (!queryTags[`${props.parentName}`])
            {
                router.push({ pathname: "/page/[page]", query: { page: router.query.page }});
                props.setFilterList(props.filterList.filter(element => element !== `${props.name}-${props.localeName}`));
                setCheck(!check);
                return;
            }
        }
        else
        {
            queryTags[`${props.parentName}`] = `${props.name}-${props.localeName}`;
        }

        // labtopL에서만 작동
        if (media.deviceLevel === "labtopL")
        {
            router.push({ pathname: "/page/[page]", query: { page: router.query.page, tags: JSON.stringify(queryTags) }});

            // 태그 element를 위한 준비
            let newArray: string[] = [ ...props.filterList ];

            if (check && props.filterList.includes(`${props.name}-${props.localeName}`))
                newArray = newArray.filter(element => element !== `${props.name}-${props.localeName}`);
            else if (!check && !props.filterList.includes(`${props.name}-${props.localeName}`))
                newArray.push(`${props.name}-${props.localeName}`);

            props.setFilterList(newArray);
        }
        else
        {
            props.setFilterQuery(JSON.stringify(queryTags));
        }

        setCheck(!check);
    };

    useEffect(() => {
        if (props.filterList.find(e => e === `${props.name}-${props.localeName}`))
            setCheck(true);
        else
        {
            setCheck(false);

            let queryTags: any = router.query.tags ? JSON.parse(router.query.tags as string) : undefined;

            if (queryTags && queryTags[`${props.parentName}`])
            {
                let tagArray = Object.entries(queryTags);

                if (tagArray.find(e => (e[1] as string) === `${props.name}-${props.localeName}`))
                {
                    queryTags[`${props.parentName}`] = queryTags[`${props.parentName}`].replace(`${`${props.name}-${props.localeName}`}`, " ").replace("+ ", "").replace(" +", "").replace(" ", "");   

                    if (queryTags[`${props.parentName}`])
                        router.push({ pathname: "/page/[page]", query: { page: router.query.page, tags: JSON.stringify(queryTags) }});
                    else
                        router.push({ pathname: "/page/[page]", query: { page: router.query.page }});
                }
            }
        }
    }, [props.filterList]);

    if (props.number === 0)
    {
        return (
            <></>
        )
    }
    else
    {
        return (
            <Wrapper onClick={ handleButtonClick }>
                <CheckBox style={{ marginRight: "8px" }} value={ check } setValue={ setCheck } />
                <MenuBox>
                    <MiddleText bold={ router.query.id === props.id && router.query.cd === props.subCd }>{ `${props.localeName}` }</MiddleText>
                    <SmallText>{ props.number.toLocaleString() }</SmallText>
                </MenuBox>
            </Wrapper>
        );
    }
}

const Wrapper = styled.div<{ indent?: number }>`
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 0.15rem;
    cursor: pointer;
`;

const MenuBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

const ArrowIcon = styled.div`
    display: flex;
    align-items: center;
`;

const NormalText = styled(P)`
    font-size: 0.20rem;
    font-weight: 300;
    text-align: left;
    line-height: normal;
    color: ${({ theme }: { theme: ThemeType }) => theme.text};
`;

const BoldText = styled(NormalText)`
    font-weight: bold;
    line-height: 0.75rem;
`;

const MiddleText = styled(NormalText)<{ bold: boolean }>`
    color: #FFF;
    font-family: Pretendard;
    font-size: 0.16rem;
    font-weight: 400;
`;

const SmallText = styled(NormalText)`
    font-size: 0.16rem;
    color: gray;
    margin-left: 5px;
`;