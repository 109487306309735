import React, { RefObject, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import cssMixin from 'src/styles';
import { useSelector } from 'react-redux';
import { RootState } from '@src/store';
import ListItem from '@src/components/common/list/ListItem';
import { t_library } from '@src/types/tables';
import { Button, P } from '@src/components/atoms';
import { ThemeType } from '@src/types/theme';
import FilterDrawer from '@src/components/common/filter/FilterDrawer';
import UserService from '@src/services/user/UserService';
import CartService from '@src/services/cart/CartService';
import Pagenation from '@src/components/common/pagenation/Pagenation';
import { useRouter } from 'next/router';
import { darkTheme } from '@src/theme/default';
import { getCookie, setCookie } from '@src/lib/util/cookie';
import { CategoryProps } from '@src/components/common/filter/category/FilterCaterogy';
import FilterMenu from '@src/components/common/filter/FilterMenu';
import { RxCross2 } from "react-icons/rx";
import Player from '@src/components/common/player/Player';
import { playerLib } from '@src/lib/player/player-lib';
import { PageProps } from '@pages/page/[page]';
import { PagePropsContext } from '@src/context/Pages';
import { useTranslation } from 'next-i18next';

export type MainLayoutProps = {
	listNum: number;
	setListNum?: any;
	filterList: string[];
	setFilterList: any;
	filterOpen: boolean;
	setFilterOpen: any;
};



export default function MainLayout(props: MainLayoutProps)
{
	const { t } = useTranslation("common");
	const media = useSelector((state: RootState) => state.media);
	const user = useSelector((state: RootState) => state.user);
	const router = useRouter();
	const playerRef = useRef<HTMLDivElement>(null);
	const audioRef = useRef<HTMLAudioElement>(null);
	const waveRef = useRef<HTMLDivElement>(null);
	const dataIdxRef = useRef<number>(-1);
	const listArrayRef: RefObject<HTMLDivElement>[] = Array.from({ length: props.listNum }, () => useRef<HTMLDivElement>(null));
	const pageProps: PageProps = useContext(PagePropsContext);

	const [ isMounted, setIsMounted ] = useState(false);
	const [ prevPath, setPrevPath ] = useState('');
	const [ buyList, setBuyList ] = useState<any[]>([]);
	const [ cartList, setCartList ] = useState<any[]>([]);
	const [ stateChange, setStateChange ] = useState<"" | "add" | "remove">("");
	const [ page, setPage ] = useState(pageProps.page);
	const [ showModal, setShowModal ] = useState(true);

    const [ hideModalForToday, setHideModalForToday ] = useState(!!getCookie("hideModalForToday"));
	const [ play, setPlay ] = useState(false);
	const [ playData, setPlayData ] = useState<t_library>();
	const [ playId, setPlayId ] = useState("");
	const [ musicUrl, setMusicUrl ] = useState("");
	const [ musicChange, setMusicChange ] = useState(false);
	const [ pageDelay, setPageDelay ] = useState(false);


	function handleFilterToggle()
	{
		props.setFilterOpen(!props.filterOpen);
	};

	function handleCloseModal()
	{
		setShowModal(false);
		setHideModalForToday(true)
	};

	function handleItemPlay(value: t_library)
	{
		setPlay(true);
		setPlayData(value);
	};

	function handleItemPause()
	{
		setPlay(false);
	};

	function handlePrevClick()
	{
		dataIdxRef.current = pageProps.mainData!.findIndex(e => e.id === playData!.id);

		if (dataIdxRef.current > 0)
		{
			let newData: t_library = pageProps.mainData![dataIdxRef.current - 1];
			setPlayId(newData.id);

			if (process.browser)
			{
				let boundingRect = listArrayRef[dataIdxRef.current - 1].current?.getBoundingClientRect()!;
				playerLib.playPrev(dataIdxRef.current, newData, handleItemPlay, musicUrl, boundingRect);
			}
		}
		else if (page > 1)
		{
			setPage(page - 1);
			setPageDelay(true);
			dataIdxRef.current = props.listNum - 1;
		}
	};

	function handleNextClick()
	{
		dataIdxRef.current = pageProps.mainData!.findIndex(e => e.id === playData!.id);

		if (dataIdxRef.current < pageProps.mainData!.length - 1)
		{
			let newData: t_library | undefined = pageProps.mainData![dataIdxRef.current + 1];

			if (process.browser)
			{
				let boundingRect = listArrayRef[dataIdxRef.current + 1].current?.getBoundingClientRect()!;
				let playerRect = playerRef.current?.getBoundingClientRect()!;
				
				playerLib.playNext(dataIdxRef.current, newData, handleItemPlay, musicUrl, boundingRect, playerRect);
			}
		}
		else if (page < Math.ceil(pageProps.dataNum / props.listNum))
		{
			setPage(page + 1);
			setPageDelay(true);
			dataIdxRef.current = 0;
		}
    };

	function handleTagClose(tag: string)
	{
		// query에 전달
        let queryTags: any = router.query.tags ? JSON.parse(router.query.tags as string) : new Object();
		let keys: string[] = Object.keys(queryTags);
		let values: string[] = Object.values(queryTags);

		for (let i = 0; i < keys.length; i++)
		{
			if (values[i].includes(tag))
			{
				let values_ = values[i].replace(tag, " ").replace("+ ", "").replace(" +", "").replace(" ", "");
				queryTags[`${keys[i]}`] = values_;
			}
		}
		
		let newArray: string[] = props.filterList.filter(e => e !== "" && e !== tag);
		props.setFilterList(newArray);

		router.push({ pathname: "/page/[page]", query: { page: router.query.page, tags: JSON.stringify(queryTags) }});
	};

    useEffect(() => {
		// setHideModalForToday(!!getCookie("hideModalForToday"))
	}, []);

	useEffect(() => {
		if (pageDelay)
		{
			let newData: t_library | undefined = pageProps.mainData![dataIdxRef.current];
			
			handleItemPlay(newData);
			URL.revokeObjectURL(musicUrl);
			setPlayId(newData.id);
			setPageDelay(false);
		}
	}, [pageProps.mainData]);

	useEffect(() => {
		if (isMounted)
		{
			if (getCookie("accessToken"))
			{
				UserService.buyList({ userId: user.id }).then((res) => setBuyList(res));
				CartService.cartLibraries({ userId: user.id }).then((res) => setCartList(res));
			}
		}
	}, [isMounted]);

	useEffect(() => {
		setPrevPath(router.asPath);
	}, [router.asPath]);

	useEffect(() => {
		if (router.query.tags)
		{
			let queryTags: any = router.query.tags ? JSON.parse(router.query.tags as string) : new Object();
			let subTags: string[] = [];
			
			for (const [key, value] of Object.entries(queryTags))
            {
                subTags.push(...(value as string).split("+"));
            }

			props.setFilterList(subTags.filter(e => e));
		}
		else
		{
			props.setFilterList([]);
		}
	}, [router.query]);

	useEffect(() => {
		if (isMounted)
		{
			router.push({
				pathname: '/page/[page]',
				query: {
					page: page,
					tags: router.query.tags
				},
			});
		}
		else
			setIsMounted(true);
	}, [page]);

	useEffect(() => {
		if (stateChange !== "" && getCookie("accessToken"))
		{
			setTimeout(() => {
				CartService.cartLibraries({ userId: user.id })
				.then(res => {
					setCartList(res)
				});
			}, 100)

			setStateChange("");
		}
	}, [stateChange]);

	useEffect(() => {
		if (hideModalForToday)
		{
			// 쿠키를 이용해서 오늘 하루 동안 모달을 보지 않음 처리
			const date = new Date();
			date.setTime(date.getTime() + (24 * 60 * 60 * 1000)); // 하루 후 만료
			setCookie("hideModalForToday", "true", { expires: date })
			setCookie("expires", date.toUTCString());
		}
	}, [hideModalForToday]);

	useEffect(() => {
		if (!playData)
			return;

        const fileId = playData.sampleFileId;

        playerLib.loadFile(fileId, setMusicUrl);
		setMusicChange(true);
    }, [playData]);

	useEffect(() => {
		let audio = audioRef.current;
	
		if (audio)
		{
			if (play)
			{
				if (musicUrl)
					audio.play();
				else
				{
					audio!.onloadeddata = (event) => {
						audio!.play();
					};
				}
			}
			else
				audio.pause();
		}
	}, [play]);

	useEffect(() => {
		if (playId)
		{
			let audio = audioRef.current;
			audio!.pause();
		}
	}, [playId]);

	// 쿠키에서 hideModalForToday 값을 가져와서 모달을 노출할지 결정
	const shouldShowModal = !hideModalForToday && showModal;

	return (
		<Wrapper>
			{/* <Modal type="image" open={ shouldShowModal } setOpen={ setShowModal }>
				<Image
					width="400"
					height="400"
					src="/images/sale.png"				
				/>
				<div style={{ width: "100%", display: "flex", position: "absolute", justifyContent: "space-between", lineHeight: "30px" }}>
					<label onClick={ handleCloseModal } style={{ fontSize: "0.16rem" }}>
						오늘 하루 열지 않기
						<input
							type="checkbox"
							onChange={ (e) => setHideModalForToday(e.target.checked) }
							style={{
								width: "16px",
								height: "16px",
								border: "none",
								cursor: "pointer",
							}}
						/>
					</label>
					<Button
						onClick={ () => setShowModal(false) }
						style={{ margin: "5px 0 0 5px", color: darkTheme.text }}
					>
						닫기
					</Button>
				</div>
			</Modal> */}
			<Wrap>
				{ media.deviceLevel === "labtopL" ?
					<FilterMenu close={ undefined } filterList={ props.filterList } setFilterList={ props.setFilterList } />
					:
					<FilterDrawer isOpend={ props.filterOpen } toggleFunc={ handleFilterToggle }>
						<FilterMenu close={ handleFilterToggle } filterList={ props.filterList } setFilterList={ props.setFilterList } />
					</FilterDrawer>
				}
				<ListWrapper>
					<P level={ 10 } color="white" fontWeight="bold" mb={ 32 }>{ "저작권 무료 한국 전통 배경음악" }</P>
					<TagWrap>
						{ props.filterList.map((tag: string, index: number) => 
							tag &&
							<Tag key={ tag + index }>
								{ tag.split("-")[1] }
								<RxCross2 size={ 18 } onClick={ () => handleTagClose(tag) } />
							</Tag>
						)}
					</TagWrap>
					{ pageProps.mainData && pageProps.mainData.map((data: t_library, index: number) =>
						<ListItem
							data={ data }
							index={ index }
							key={ "item_" + index }
							isPurchase={ buyList.some(e => e && e.LIBRARY_USE_ID === data.id) }
							isInCart={ cartList.some(e => e && e.LIBRARY_ID === data.id) }
							setStateChange={ setStateChange }
							handleItemPlay={ handleItemPlay }
							handleItemPause={ handleItemPause }
							play={ play }
							playId={ playId }
							setPlayId={ setPlayId }
							divRef={ listArrayRef[index] }
							listType="library"
						/>
					)}

					<Pagenation selectedPage={ page } setSelectedPage={ setPage } listNum={ props.listNum! } />
				</ListWrapper>
				{ playData &&
					<Player
						data={ playData }
						playerRef={ playerRef }
						srcUrl={ musicUrl }
						waveRef={ waveRef }
						audioRef={ audioRef }
						play={ play }
						setPlay={ setPlay }
						musicChange={ musicChange }
						setMusicChange={ setMusicChange }
						isPurchase={ buyList && buyList.some(e => e && e.LIBRARY_USE_ID === playData.id) }
						isInCart={ cartList && cartList.some(e => e && e.LIBRARY_ID === playData.id) }
						setStateChange={ setStateChange }
						handlePrevClick={ handlePrevClick }
						handleNextClick={ handleNextClick }
					/>
				}

			</Wrap>
		</Wrapper>
	);
}

const Wrapper = styled.main`
	${ cssMixin.container };

	${({ theme }: { theme: ThemeType }) => theme.smallTablet`
		padding: 0 5%;
	`};
`;

const Wrap = styled.div`
	${ cssMixin.container };
	min-height: 100vh;
	display: flex;

	${({ theme }: { theme: ThemeType }) => theme.labtopL`
		display: block;
	`};
`;

const TagWrap = styled.div`
	/* width: 100%; */
	display: flex;
	margin-bottom: 10px;
`;

const Tag = styled.div`
    width: fit-content;
	height: 34px; 
    border-radius: 100px; 
    border: 1px solid #999; 
	margin-right: 8px;
    padding: 2px 8px 2px 12px;
	display: flex;
	align-items: center;
    color: #FFF;
	font-family: Pretendard;
	font-size: 14px;
	font-weight: 700;
`;

const ListWrapper = styled.div`
	width: 100%;
	padding: 32px 30px 32px 40px;

	${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        padding: 32px 0 32px 0;
    `};

	&>p {
		${({ theme }: { theme: ThemeType }) => theme.largeTablet`
			width: 100%;
			text-align: center; 
			word-break: keep-all;
		`};

		${({ theme }: { theme: ThemeType }) => theme.smallTablet`
        	font-size: 0.27rem;
    	`};
	}
`;