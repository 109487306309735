import React, { useContext, useEffect, useState } from 'react';
import CommonLayout from '@src/layouts/common/CommonLayout'
import MainLayout from '@src/layouts/main/MainLayout'
import LibraryService from '@src/services/library/LibraryService';
import { cvtJson2Library } from '@src/lib/public-lib';
import { t_library } from '@src/types/tables';
import { CategoryProps } from '@src/components/common/filter/category/FilterCaterogy';
import TopBar from '@src/layouts/main/top/TopBar';
import { PagePropsContext } from '@src/context/Pages';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';

const meta = {
	title: 'YULBGM',
	siteDescription: 'YULCOMPANY Inc',
	pageUrl: 'https://www.yulbgm.com',
};

export type PageProps = {
    page: number;
    mainData: t_library[];
    categoryData: CategoryProps[];
    tagData: string;
    dataNum: number;
};

export default function Page(props: PageProps)
{
    const [ listNum, setListNum ] = useState<number>(20);
    const [ filterList, setFilterList ] = useState<string[]>([]);
    const [ filterOpen, setFilterOpen ] = useState(false);

    return (
        <CommonLayout { ...meta }>
            <PagePropsContext.Provider value={ props }>
                <TopBar
                    fliterNum={ filterList.length }
                    filterOpen={ filterOpen }
                    setFilterOpen={ setFilterOpen }
                />
                <MainLayout
                    listNum={ listNum }
                    filterList={ filterList }
                    setFilterList={ setFilterList }
                    filterOpen={ filterOpen }
                    setFilterOpen={ setFilterOpen }
                />
            </PagePropsContext.Provider>
        </CommonLayout>
    );
}

export async function getServerSideProps(context: any)
{
    const { params, query } = context;
    const page: number = params.page ? parseInt(params.page) : 1;
    const listNum: number = query && query.listNum ? parseInt(query.listNum) : 20;
    const filter = query.tags ? query.tags : query.search;
    let mainData: any[] = [];

    // 메인 데이터
    if (query.search)
    {
        const searchRes = await LibraryService.searchList({ keywords: filter, limit: listNum, page: page });
        mainData = params.page > 0 ? searchRes.map((data: any) => 
            cvtJson2Library(data),
        ) : [];
    }
    else
    {
        const mainRes = await LibraryService.mainList({ limit: listNum, page: page, filter: filter, locale: context.locale });
        mainData = params.page > 0 ? mainRes.map((data: any) => 
            cvtJson2Library(data),
        ) : [];
    }

    // 카테고리 이름 정보
    const codeName: string = context.locale === "ko" ? "MST_CD_NM" : "MST_CD_NM_EN";
    const categoryRes = await LibraryService.categoryList({ target: ["MST_CD", codeName], locale: context.locale });
    const categoryData: CategoryProps[] = categoryRes.map((data: any) => {
        return {
            id: data.MST_CD,
            name: context.locale === "ko" ? data.MST_CD_NM : data.MST_CD_NM_EN,
            num: 0,
            tags: ""
        }
    });

    // 태그 데이터
    const tagMap: Map<string, string> = new Map();
    const libraryRes = await LibraryService.libraryList({ locale: context.locale, target: ["LIBRARY_GENRE_CD", "MOOD_CD", "THEME_CD", "SCENE_CD", "SOUND_EFFECT_CD", "BGM_CD", "LIBRARY_INST_CD", "NATION"], limit: 0, page: 0 });
    libraryRes.map((data: any) => {
        if (data.LIBRARY_GENRE_CD)
            tagMap.set("LIBRARY_GENRE_CD", (tagMap.get("LIBRARY_GENRE_CD") ? tagMap.get("LIBRARY_GENRE_CD") + (data.LIBRARY_GENRE_CD + ", ") : data.LIBRARY_GENRE_CD + ", "));
        if (data.MOOD_CD)
            tagMap.set("MOOD_CD", (tagMap.get("MOOD_CD") ? tagMap.get("MOOD_CD") + (data.MOOD_CD + ", ") : data.MOOD_CD + ", "));
        if (data.THEME_CD)
            tagMap.set("THEME_CD", (tagMap.get("THEME_CD") ? tagMap.get("THEME_CD") + (data.THEME_CD + ", ") : data.THEME_CD + ", "));
        if (data.SCENE_CD)
            tagMap.set("SCENE_CD", (tagMap.get("SCENE_CD") ? tagMap.get("SCENE_CD") + (data.SCENE_CD + ", ") : data.SCENE_CD + ", "));
        if (data.LIBRARY_GENRE_CD)
            tagMap.set("LIBRARY_GENRE_CD", (tagMap.get("LIBRARY_GENRE_CD") ? tagMap.get("LIBRARY_GENRE_CD") + (data.LIBRARY_GENRE_CD + ", ") : data.LIBRARY_GENRE_CD + ", "));
        if (data.SOUND_EFFECT_CD)
            tagMap.set("SOUND_EFFECT_CD", (tagMap.get("SOUND_EFFECT_CD") ? tagMap.get("SOUND_EFFECT_CD") + (data.SOUND_EFFECT_CD + ", ") : data.SOUND_EFFECT_CD + ", "));
        if (data.BGM_CD)
            tagMap.set("BGM_CD", (tagMap.get("BGM_CD") ? tagMap.get("BGM_CD") + (data.BGM_CD + ", ") : data.BGM_CD + ", "));
        if (data.LIBRARY_INST_CD)
            tagMap.set("LIBRARY_INST_CD", (tagMap.get("LIBRARY_INST_CD") ? tagMap.get("LIBRARY_INST_CD") + (data.LIBRARY_INST_CD + ", ") : data.LIBRARY_INST_CD + ", "));
        if (data.NATION)
            tagMap.set("NATION", (tagMap.get("NATION") ? tagMap.get("NATION") + (data.NATION + ", ") : data.NATION + ", "));
    });
    
    const tagData: string = JSON.stringify(Object.fromEntries(tagMap));
    const dataRes = await LibraryService.dataNum({ tags: query.tags, search: query.search });
    
    return {
        props: {
            page,
            mainData,
            categoryData,
            tagData,
            dataNum: dataRes,
            ...(await serverSideTranslations(context.locale ?? "en", ['common', 'header'])),
        }
    }
}
