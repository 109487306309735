import styled from "styled-components"
import Drawer from "@mui/material/Drawer"
import { RootState } from "@src/store";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { darkTheme } from "@src/theme/default";

export type FilterDrawerProps = {
    isOpend: boolean;
    toggleFunc: any;
    setCtgName?: any;
    setSubName?: any;
    children: any;
}

export default function FilterDrawer(props: FilterDrawerProps)
{
    const media = useSelector((state:RootState) => state.media);
    const [ width, setWidth ] = useState("40%");

    useEffect(() => {
        if (media.isMobile)
            setWidth("100%");
        else
            setWidth("40%");
    }, [media]);

    return (
        <Wrapper
            PaperProps={
                { sx: { width: "fit-content", height: "100%", color: darkTheme.textHighlight, backgroundColor: "#1B0E47" } }
            }
            anchor="left"
            open={ props.isOpend }
        >
            { props.children }
        </Wrapper>
    )
}

const Wrapper = styled(Drawer)`
    width: 100%;
`;