import axios, { AxiosError, AxiosResponse } from 'axios';
import { categoryConfig, dataNumConfig, listConditionConfig, listConfig, listSubjectConfig, mainConfig, searchListConfig, subCategoryConfig } from './config';
import { CustomError } from 'src/models/error/http';

async function dataNum(data: { tags?: any, search?: string })
{
	let res = await axios(dataNumConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e.response!);
		});

	return res;
}

async function mainList(data: { limit: number, page: number, filter?: any, locale: string })
{
	let res = await axios(mainConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e.response!);
		});

	return res;
}

async function libraryList(data: { locale: string, target: string[], libraryId?: string[], cond?: string[], limit: number, page: number })
{
	let res = await axios(listConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e.response!);
		});

	return res;
}

async function libraryListSubject(data: { target: string[], ctg?: string, subCd?: string, limit: number, page: number })
{
	let res = await axios(listSubjectConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e.response!);
		});

	return res;
}

async function libraryListCondition(data: { target: string[], cond: string, limit: number, page: number })
{
	let res = await axios(listConditionConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e.response!);
		});

	return res;
}

async function categoryList(data: { target?: string[], categoryId?: string[], locale?: string })
{
	let res = await axios(categoryConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e.response!);
		});

	return res;
}

async function subCategoryList(data: { target: string[], category: string, subCd: string })
{
	let res = await axios(subCategoryConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e.response!);
		});

	return res;
}

async function searchList(data: { keywords: string, limit: number, page: number })
{
	let res = await axios(searchListConfig(data))
		.then((res: AxiosResponse<any>) => res.data.results)
		.catch((e: AxiosError<CustomError>) => {
			console.log(e.response!);
		});

	return res;
}

const LibraryService = {
	dataNum,
	mainList,
	libraryList,
	libraryListSubject,
	categoryList,
	subCategoryList,
	libraryListCondition,
	searchList,
};

export default LibraryService;
